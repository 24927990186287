<template>
  <card-component
    title="Login"
    icon="lock"
  >
    <router-link
      slot="button"
      to="/"
      class="button is-small"
    >
      Dashboard
    </router-link>

    <form
      method="POST"
      @submit.prevent="submit"
    >
      <b-field label="Usuário">
        <b-input
          v-model="form.username"
          name="username"
          type="text"
          required
        />
      </b-field>

      <b-field label="Senha">
        <b-input
          v-model="form.password"
          type="password"
          name="password"
          required
        />
      </b-field>

      <!-- <b-field>
        <b-checkbox
          v-model="form.remember"
          type="is-black"
          class="is-thin"
        >
          Remember me
        </b-checkbox>
      </b-field> -->

      <hr>

      <b-field grouped position="is-right">
        <div class="control">
          <b-button
            native-type="submit"
            type="is-black"
            :loading="isLoading"
          >
            Login
          </b-button>
        </div>
        <!-- <div class="control">
          <router-link
            to="/"
            class="button is-outlined is-black"
          >
            Dashboard
          </router-link>
        </div> -->
      </b-field>
    </form>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent.vue'
import { userLogin } from '@/core'

export default {
  name: 'Login',
  components: { CardComponent },
  data () {
    return {
      isLoading: false,
      form: {
        username: null,
        password: null,
        remember: false
      }
    }
  },
  methods: {
    submit () {
      if (this.form.username && this.form.password) {
        this.isLoading = true
        userLogin(this.form.username, this.form.password)
          .then(response => {
            if (response.result === 0) {
              setTimeout(() => {
                this.$router.push('/')
                this.isLoading = false
              }, 700)
            }
          })
          .catch(error => {
            console.error(error)
            this.$buefy.snackbar.open({
              message: error.message || 'Usuário ou senha não encontrado',
              queue: false
            })
            this.isLoading = false
          })
      }
    }
  }
}
</script>
